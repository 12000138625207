import axios from 'axios'

const api = axios.create({
baseURL: 'http://43.228.126.245:4000',
 //baseURL: 'https://auditcrm.unitdtechnologies.com:4001/api'

//baseURL: 'http://localhost:3001',
});





export default api